import {
  styled,
  Typography,
  FormControl,
  Select,
  MenuItem,
  TextField,
  IconButton,
  Tooltip,
  tooltipClasses,
  Grid,
  Autocomplete,
  FormGroup,
  FormControlLabel,
  Dialog,
  createFilterOptions,
  DialogActions,
  Button,
  Divider,
  Stack,
  DialogTitle,
  DialogContent,
  Zoom,
} from "@mui/material";
import Compressor from "compressorjs";
import { LazyLoadImage } from "react-lazy-load-image-component";
import CamBtnIcon from "../../../../assets/Icons/camBtnIcon.svg";
import noImageGray from "../../../../assets/images/noImageGray.png";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import PostAIModal from "../../../../components/Models/AIModal/PostAIModal";
import RefreshIcon from "@mui/icons-material/Refresh";
import { InputAdornment } from "@material-ui/core";
import React, { useEffect, useState, useRef } from "react";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Box } from "@mui/system";
import Checkbox from "@mui/material/Checkbox";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";
import { api } from "../../../../contexts/JWTContext";
import CommonButton from "../../../../components/CustomComponents/Buttons/bulkButton";
import { LoadingButton } from "@mui/lab";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import BulkUpdateModel from "./Components/BulkUpdateModel";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { ReactComponent as AiLogoGrey } from "../../../../assets/images/AIlogogrey.svg";
import { toast } from "react-toastify";
import { useStyles } from "../styles/style";
import dayjs from "dayjs";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CloseIcon from "@mui/icons-material/Close";
import PostCustomModel from "../../../../components/Models/PostCustomModel";
import EditIcon from "@mui/icons-material/Edit";
import ProfilePictureModal from "./Components/PictureModalCard";

// import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
// import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CalendarMonthIcon from "../../../../assets/Icons/Calender.svg";
import Delete from "../../../../components/Models/DeleteModal/Delete";
import useDateFormat from "../../../../hooks/useDateFormat";
import { languagesDataTwo } from "../../../../assets/defaultLanguage";
const Item = styled(Box)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  // textAlign: "center",
  color: theme.palette.text.secondary,
}));
const Transition = React.forwardRef((props, ref) => (
  <Zoom ref={ref} {...props} style={{ transitionDelay: "200ms" }} />
));
Transition.displayName = "Transition";

const CustomWidthTooltipOfDelete = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "red",
  },
});
const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "grey",
  },
});
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));

const PostCard = ({
  index,
  data,
  postData,
  handleChangeStartDate,
  handleChangeEndDate,
  handleChangeRepeatPost,
  handleChangeFrequency,
  handleChangeOccurance,
  handleChangeCustomDates,
  handleChangeCustomDays,
  handleChangeBusinessCta,
  handleChangeButtonUrl,
  handleChangeCheckbox,
  handleChangeCheckboxUberAll,
  handleChangeDescription,
  handleDeletePost,
  showEditAll,
  setShowEditAll,
  handleChangeLocation,
  handleChangeTitle,
  bulkStartDate,
  setBulkStartDate,
  checkRequired,
  handleChangeAIDescriptionForBulk,
  changeHandlerBulkDateUpdate,
  handleChangeLocationData,
  handleChangeAIDescription,
  setLocationRequired,
  locationRequired,
  startDateRequired,
  setStartDateRequired,
  handleMakeDefaultLocation,
  setValidDate,
  ValidDate,
  setEndValidDate,
  EndValidDate,
  endDateGreater,
  setEndDateGreater,
  handleChangeAIDescriptionForBulkTwo,
  handleClickCloseWarning,
  setSelectedPostData,
  selectedPostData,
  setPostData,
  handleUpdatePostData,
  allLocation,
  loctionLoadings,
  defaultLocation,
  setDefaultLocation,
  defaultLocationByDefault,
  setDefaultLocationByDefault,
  bulkImages,
  updateBulkImages,
  submitted,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const inputFileRef = React.useRef();
  const [submitLoading, setSubmitLoading] = useState(false);

  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const [descriptionText, setDescription] = useState(0);
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(false);
  const [AiContentDescription, setAiContentDescription] = useState("");
  const [generatedContent, setGeneratedContent] = useState("");
  const [repeatPost, setRepeatPost] = useState(null);
  const [displayUrlField, setDisplayUrlField] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [endDateRequired, setEndDateRequired] = useState(false);
  const [checked, setChecked] = useState(true);
  const [checkedUberAll, setCheckedUberAll] = useState(false);
  const [urlRequired, setUrlRequired] = useState(false);

  const [locationId, setLocationId] = useState([]);
  const [showBulkUpdateTime, setShowBulkUpdateTime] = useState(false);
  const textArea = useRef();
  const [displayError, setDisplayError] = useState(false);
  const [openDefaultModel, setOpenDefaultModel] = useState(false);
  const [contentRequired, setContentRequired] = useState(false);
  const [tagsRequired, setTagsRequired] = useState(false);
  const [selectAll, setSelectAll] = useState(false);

  const [openedFromBulk, setOpenedFromBulk] = useState(false);

  const filter = createFilterOptions();
  const [limitNumber, setLimitNumber] = useState("160");
  const [openCustomModel, setOpenCustomModel] = useState(false);
  const [frequencyValue, setFrequencyValue] = useState("");
  const [occuranceValue, setOccuranceValue] = useState(1);
  const [selectedWeekDays, setSelectedWeekDays] = useState([]);
  const [selectedMonthDays, setSelectedMonthDays] = useState([]);
  const [customError, setCustomError] = useState(false);
  const [loadImg, setLoadImg] = useState(false);
  const [selectedImagesToChange, setSelectedImagesToChange] = useState([]);
  const [editUploadedImage, setEditUploadedImage] = useState([]);
  // const [displayImage, setDisplayImage] = useState(null);
  // const [editImage, setEditImage] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [openPictureModal, setOpenPictureModal] = useState(false);
  //  const [displayUberAllError, setDisplayUberAllError] = useState(false);
  const [openOptimizeImageModel, setOpenOptimizeImageModel] = useState(false);
  const [newIndexValue, setNewIndexValue] = useState(null);
  const [resizeLoading, setResizeLoading] = useState(false);
  const [translatePrompLoading, setTranslatePrompLoading] = useState(false);
  const [userLang, setUserLang] = useState(localStorage?.getItem("i18nextLng"));

  const { format: dateFormatString } = useDateFormat();

  const [translatePromptAgainLoading, setTranslatePromptAgainLoading] =
    useState(false);

  const currentDate = dayjs(new Date());
  useEffect(() => {
    if (checkRequired === true) {
      if (data?.startDate === null) {
        setStartDateRequired(true);
      } else {
        setStartDateRequired(false);
      }
      if (data?.locationIds.length === 0) {
        setLocationRequired(true);
      } else {
        setLocationRequired(false);
      }
      if (data?.url === "" || !validateUrl(data?.url)) {
        setUrlRequired(true);
      } else {
        setUrlRequired(false);
      }
    }
  }, [checkRequired]);

  useEffect(() => {
    if (data?.locationData) {
      setLocationId(data?.locationData);
    }
  }, [data?.locationData]);

  useEffect(() => {
    if (selectedImagesToChange?.length > 0) {
      setOpenPictureModal(true);
    }
  }, [selectedImagesToChange]);

  useEffect(() => {
    if (allLocation?.results?.length > 0 && postData?.length > 0) {
      const defaultLocationObj = allLocation?.results?.find(
        (item) => item?.defaultLocation !== null
      );
      if (defaultLocationObj !== undefined && defaultLocationObj !== null) {
        //  setDefaultLocation(defaultLocationObj);

        handleMakeDefaultLocation(defaultLocationObj, index);
      } else {
        handleMakeDefaultLocation(allLocation?.results[0], index);
      }
    }
  }, [allLocation]);

  const handleOpenTemplateMenu = (dataValue) => {
    if (dataValue === undefined) {
      setOpenedFromBulk(true);
    } else {
      setOpenedFromBulk(false);
    }
    setOpenDefaultModel(true);
  };
  const handleCloseTemplateMenu = () => {
    setOpenDefaultModel(false);
    setLimitNumber("160");
  };

  const handleUpdateDateAndTime = () => {
    setShowBulkUpdateTime(true);
  };

  const handleCloseBlukUpdateModel = () => {
    setShowBulkUpdateTime(false);
  };
  /*  const handleLocationChange = (option) => {
    setLocationId(option);
    if (option?.length > 0) {
      const defaultLocationObj = option?.find(
        (item) => item?.defaultLocation !== null
      );
      if (defaultLocationObj !== undefined && defaultLocationObj !== null) {
        if (data === undefined) {
          setDefaultLocation(defaultLocationObj);
        } else {
          handleMakeDefaultLocation(defaultLocationObj, index);
        }
      } else {
        if (data === undefined) {
          setDefaultLocation(option[0]);
        } else {
          handleMakeDefaultLocation(option[0], index);
        }
      }
    }
  }; */

  const handleCurrentDateAndTime = () => {
    var currentdate = dayjs(new Date());
    setStartDate(currentdate);
    data["startDate"] = currentdate;
    setStartDateRequired(false);
    if (
      data?.repeat !== "no" ||
      (data === undefined &&
        (repeatPost === null || repeatPost !== "no" || repeatPost === "custom"))
    ) {
      if (dayjs(new Date(data?.endDate)) > dayjs(new Date(data?.startDate))) {
        setEndDateGreater(false);
      } else {
        setEndDateGreater(true);
      }
    }
  };
  const generateContent = async (data) => {
    /*   if (tags?.length===0) {
      setTagsRequired(true);
    } else {
      setTagsRequired(false);
    } */
    if (AiContentDescription?.length === 0) {
      setContentRequired(true);
    } else {
      setContentRequired(false);
    }
    if (AiContentDescription?.length > 0) {
      try {
        setLoading(true);

        const res = await api.post(`/openAi/generateContent`, {
          prompt: data,
        });
        if (res.status === 200) {
          setLoading(false);
          setDisplayError(false);
          setGeneratedContent(res?.data?.data?.replace(/"/g, ""));
        } else {
          toast.error("Something went wrong");
        }
      } catch (error) {
        setDisplayError(true);
        setLoading(false);
        toast.error(error?.response?.data?.message);
      }
    }
  };

  const generateContentFromBulk = async (dataValue) => {
    /*   if (tags?.length===0) {
      setTagsRequired(true);
    } else {
      setTagsRequired(false);
    } */
    if (AiContentDescription?.length === 0) {
      setContentRequired(true);
    } else {
      setContentRequired(false);
    }
    if (AiContentDescription?.length > 0) {
      try {
        setLoading(true);

        const res = await api.post(`/location/open-ai-description`, {
          prompt: dataValue,
          postsCount: postData?.length,
        });
        if (res.status === 200) {
          setLoading(false);
          setDisplayError(false);
          setOpenDefaultModel(false);
          setLimitNumber("160");

          handleChangeAIDescriptionForBulkTwo(res?.data?.data, index);
          //  setGeneratedContent(res?.data?.data);
        } else {
          toast.error("Something went wrong");
        }
      } catch (error) {
        setDisplayError(true);
        setLoading(false);
        toast.error(error?.response?.data?.message);
      }
    }
  };
  const generateAIContent = () => {
    const lang = `Please give response in ${
      languagesDataTwo.find((item) => item.value === userLang)?.language
    } language: ${AiContentDescription}`;
    const str = "\n\nproduct:";
    const str2 = "\n\n";
    const joinedStr = lang.concat(str);
    /* const finalStr = joinedStr tags.toString(); */
    let finalStr = "";
    if (tags?.length > 0) {
      finalStr = joinedStr + tags.toString();
    } else {
      finalStr = lang.concat(str2);
    }
    if (openedFromBulk) {
      generateContentFromBulk(finalStr);
    } else {
      generateContent(finalStr);
    }
  };
  const handleSubmit = () => {
    handleCloseTemplateMenu();
    handleChangeAIDescription(generatedContent, index);
    handleChangeAIDescriptionForBulk &&
      handleChangeAIDescriptionForBulk(generatedContent, index);
    setDescription(generatedContent?.length);
  };
  function isValidDateFormat(dateString, format) {
    return dayjs(dateString, { format, strict: true }).isValid();
  }
  const handleCloseCustomModel = () => {
    setOpenCustomModel(false);
    /*  if (
      (data === undefined &&
        selectedMonthDays?.length === 0 &&
        frequencyValue === "monthly") ||
      (selectedWeekDays?.length === 0 && frequencyValue === "weekly") ||
      occuranceValue === 0 ||
      (data !== undefined &&
        data?.customSchedule?.occurance === 0 &&
        (data?.customSchedule?.frequency === "monthly" ||
          data?.customSchedule?.frequency === "weekly"))
    ) {
      setCustomError(true);
    } else {
      setCustomError(false);
    }

    if (
      data === undefined &&
      frequencyValue === "" &&
      repeatPost === "custom"
    ) {
      setRepeatPost("no");
      handleChangeRepeatPost("no");
    } else if (
      data !== undefined &&
      data?.customSchedule?.frequency === "" &&
      data?.repeat === "custom"
    ) {
      handleChangeRepeatPost("no", index);
    } */
    // setSelectedPostData(null);
  };

  const handleBeforeLOad = () => {
    setLoadImg(true);
  };
  const handleAfterLOad = () => {
    setLoadImg(false);
  };

  const onFileChangeCapture = (e) => {
    let tempImages = [];

    for (const [key, value] of Object.entries(e?.target?.files)) {
      new Compressor(value, {
        quality: 0.8,
        success: (compressedResult) => {
          // setCompressedFile(compressedResult);

          tempImages.push({
            picture: compressedResult,
            picturePreview: URL.createObjectURL(compressedResult),
          });
          setSelectedImagesToChange([...tempImages]);
        },
      });
    }

    setIsUploading(false);
  };

  const handleClose = () => {
    document.getElementById("file-id1").value = "";
    setOpenPictureModal(false);
    //  setEditUploadedImage([]);
    setSelectedImagesToChange([]);
    setIsUploading(false);
  };
  const handleSubmitPicture = () => {
    setSubmitLoading(true);

    document.getElementById("file-id1").value = "";
    if (editUploadedImage?.length > 0) {
      //  setEditImage(editUploadedImage[0]?.postPictures);
      //  setDisplayUberAllError(editUploadedImage[0]?.errorUberAll);

      let tempArray = [...postData];
      tempArray[index]["postPictures"] = editUploadedImage[0]?.postPictures;
      setPostData(tempArray);
    }
    /*  if (selectedImagesToChange?.length > 0) {
      setDisplayImage(selectedImagesToChange[0]?.picturePreview);
    } */
    setSubmitLoading(false);
    setOpenPictureModal(false);

    setSelectedImagesToChange([]);
    setEditUploadedImage([]);
    setIsUploading(false);
  };

  const afterUploadSelectedChange = (indexNum, keyNum) => {
    const tempArray = [...selectedImagesToChange];
    const tempObj = selectedImagesToChange[indexNum];
    tempObj["key"] = keyNum;
    tempArray.splice(indexNum, 1, tempObj);
    setSelectedImagesToChange(tempArray);
  };

  const handleImageErrorToChange = (index, key) => {
    let temmpArray = [...selectedImagesToChange];
    let temmpObj = temmpArray[index];

    if (key !== undefined && key === "uberAll") {
      temmpObj["errorUberAll"] = true;
      temmpArray[index] = temmpObj;
    } else {
      temmpObj["error"] = true;
      temmpArray[index] = temmpObj;
    }
    setSelectedImagesToChange(temmpArray);
  };

  const handleDeleteSelectedImage = (index) => {
    const deleteImage = selectedImagesToChange?.filter(
      (item, indx) => indx !== index
    );

    const findDeleted = selectedImagesToChange?.find(
      (item, indx) => indx === index
    );
    if (findDeleted) {
      const filterDelete = editUploadedImage?.filter(
        (item) => item?.key !== findDeleted.key
      );
      setEditUploadedImage(filterDelete);
    }

    setSelectedImagesToChange(deleteImage);

    // Check if there are no more selected images after deletion and update isUploading state accordingly
    if (deleteImage.length >= 0) {
      setIsUploading(false);
    }
    setOpenPictureModal(false);
    // navigate(-1);
  };

  const handleClickCloseWarningToChange = (index) => {
    let temmpArray = [...selectedImagesToChange];
    let temmpObj = temmpArray[index];
    temmpObj["errorUberAll"] = false;
    temmpArray[index] = temmpObj;
    setSelectedImagesToChange(temmpArray);
  };

  const handleOptimize = () => {
    setResizeLoading(true);

    // if (newIndexValue >= 0 && newIndexValue < selectedImages.length) {
    const fileToOptimize = selectedImagesToChange[newIndexValue];
    const tempImages = [...selectedImagesToChange];
    new Compressor(fileToOptimize?.picture, {
      quality: 0.8,
      maxWidth: 2900,
      maxHeight: 2900,
      success: (compressedResult) => {
        tempImages[newIndexValue] = {
          picture: compressedResult,
          picturePreview: URL.createObjectURL(compressedResult),
        };
        setSelectedImagesToChange(tempImages); // Update state after compression
        setResizeLoading(false);
        handleCloseOptimizeImageModel();
      },
      error: (err) => {
        setResizeLoading(false);
        handleCloseOptimizeImageModel();
      },
    });
    // }
  };

  const handleOpenOptimizeImageModel = (indValue) => {
    setNewIndexValue(indValue);
    setOpenOptimizeImageModel(true);
  };
  const handleCloseOptimizeImageModel = () => {
    setOpenOptimizeImageModel(false);
  };

  const handleButtonClick = () => {
    inputFileRef.current.click();
  };

  const validateUrl = (value) => {
    const urlPattern = new RegExp(
      "(https?:\\/\\/(?:www\\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\\.[^\\s]{2,}|www\\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\\.[^\\s]{2,}|https?:\\/\\/(?:www\\.|(?!www))[a-zA-Z0-9]+\\.[^\\s]{2,}|www\\.[a-zA-Z0-9]+\\.[^\\s]{2,})"
    );
    return !!value.match(urlPattern);
  };

  const handleChange = (e, i) => {
    const { value } = e.target;
    setUrlRequired(!validateUrl(value));
    handleChangeButtonUrl(e, i);
    if (value === "") {
      setUrlRequired(false);
    }
  };

  return (
    <>
      {openOptimizeImageModel && (
        <Dialog
          open={openOptimizeImageModel}
          onClose={handleCloseOptimizeImageModel}
          aria-labelledby="responsive-dialog-title"
        >
          <Delete
            title={t("edit_image_tooltipText")}
            description={t("edit_image_tooltipText_subHead")}
            onConfirm={handleOptimize}
            loading={resizeLoading}
            onCancel={handleCloseOptimizeImageModel}
          />
        </Dialog>
      )}
      {openPictureModal && (
        <Dialog
          open={openPictureModal}
          disableBackdropClick
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "800px", // Set your width here
                borderRadius: "8px",
              },
            },
          }}
          TransitionComponent={Transition}
          keepMounted
          //  onClose={handleCloseNavigate}
          fullWidth
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          {selectedImagesToChange?.length > 0 && (
            <>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <DialogTitle
                  className={classes.dialogTitle}
                  id="alert-dialog-slide-title"
                >
                  {t("Uploading_Images")}
                </DialogTitle>
                <IconButton
                  onClick={() => handleClose()}
                  aria-label="save"
                  hover="none"
                  className={classes.closeIconButton}
                >
                  X
                </IconButton>
              </Stack>

              <DialogContent>
                <Stack direction="column">
                  <Item>
                    {selectedImagesToChange && (
                      <>
                        {selectedImagesToChange
                          ?.slice(0, 1)
                          ?.map((img, index) => {
                            return (
                              <ProfilePictureModal
                                img={img}
                                handleDelete={handleDeleteSelectedImage}
                                locationId={undefined}
                                setPostData={setEditUploadedImage}
                                postData={editUploadedImage}
                                handleClose={handleClose}
                                index={index}
                                isUploading={isUploading}
                                setIsUploading={setIsUploading}
                                afterUploadSelectedChange={(key) =>
                                  afterUploadSelectedChange(index, key)
                                }
                                handleImageError={handleImageErrorToChange}
                                selectedImages={selectedImagesToChange}
                                setSelectedImages={setSelectedImagesToChange}
                                handleClickCloseWarning={
                                  handleClickCloseWarningToChange
                                }
                                handleOpenOptimizeImageModel={
                                  handleOpenOptimizeImageModel
                                }
                              />
                            );
                          })}
                      </>
                    )}
                  </Item>

                  <Divider />
                  <Item className={classes.itemStyling}>
                    {selectedImagesToChange?.length > 0 && (
                      <>
                        <Button
                          className={classes.cancelButtonStyling}
                          variant="outlined"
                          // color="#1B2430"

                          onClick={() => handleClose()}
                        >
                          {t("Cancel")}
                        </Button>
                        &nbsp; &nbsp;
                        <CommonButton
                          disabled={
                            selectedImagesToChange.find(
                              (item) => item?.error === true
                            )
                              ? true
                              : editUploadedImage?.length ===
                                  selectedImagesToChange?.length || !isUploading
                              ? false
                              : true
                          }
                          onSubmit={() =>
                            editUploadedImage?.length ===
                            selectedImagesToChange?.length
                              ? handleSubmitPicture()
                              : setIsUploading(true)
                          }
                          loading={submitLoading}
                          label={
                            editUploadedImage?.length ===
                            selectedImagesToChange?.length
                              ? t("Submit")
                              : t("Upload")
                          }
                        />
                      </>
                    )}
                  </Item>
                </Stack>
              </DialogContent>
            </>
          )}

          <DialogActions>
            <Grid
              container
              display="flex"
              justifyContent="space-between"
            ></Grid>
          </DialogActions>
        </Dialog>
      )}
      {openCustomModel && (
        <PostCustomModel
          title={t("Custom_Repeat_Date")}
          onCancel={handleCloseCustomModel}
          open={openCustomModel}
          frequencyValue={frequencyValue}
          setFrequencyValue={setFrequencyValue}
          occuranceValue={occuranceValue}
          setOccuranceValue={setOccuranceValue}
          selectedWeekDays={selectedWeekDays}
          setSelectedWeekDays={setSelectedWeekDays}
          selectedMonthDays={selectedMonthDays}
          setSelectedMonthDays={setSelectedMonthDays}
          selectedPostData={data}
          index={index}
          handleChangeFrequency={handleChangeFrequency}
          handleChangeOccurance={handleChangeOccurance}
          handleChangeCustomDates={handleChangeCustomDates}
          handleChangeCustomDays={handleChangeCustomDays}
          setOpenCustomModel={setOpenCustomModel}
          handleChangeRepeatPost={handleChangeRepeatPost}
        />
      )}
      {openDefaultModel && (
        <Dialog maxWidth maxHeight open={openDefaultModel}>
          <PostAIModal
            title={t("AI Writer")}
            onCancel={handleCloseTemplateMenu}
            tags={tags}
            setTags={setTags}
            displayError={displayError}
            AiContentDescription={AiContentDescription}
            setAiContentDescription={setAiContentDescription}
            generatedContent={generatedContent}
            setGeneratedContent={setGeneratedContent}
            setTagsRequired={setTagsRequired}
            tagsRequired={tagsRequired}
            setContentRequired={setContentRequired}
            contentRequired={contentRequired}
            defaultLocation={
              data !== undefined && data?.defaultLocation !== null
                ? data?.defaultLocation
                : defaultLocation
            }
            limitNumber={limitNumber}
            setLimitNumber={setLimitNumber}
            translatePromptAgainLoading={translatePromptAgainLoading}
            setTranslatePromptAgainLoading={setTranslatePromptAgainLoading}
            translatePrompLoading={translatePrompLoading}
            setTranslatePrompLoading={setTranslatePrompLoading}
            userLang={userLang}
            setUserLang={setUserLang}
          />
          <Box className={classes.postDialog}>
            <Grid container>
              <Grid item xs={8} sm={8} md={8} lg={8}></Grid>
              <Grid
                item
                xs={4}
                sm={4}
                md={4}
                lg={4}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                {generatedContent?.length > 0 && (
                  /*   <CommonButton
                displayWhite="true"
                onSubmit={generateAIContent}
                label={<RefreshIcon />}
                disabled={loading}
                // label={t("Cancel")}
              /> */
                  <LoadingButton
                    loading={loading}
                    type="submit"
                    fullWidth
                    variant="outlined"
                    disabled={
                      loading ||
                      tagsRequired ||
                      translatePrompLoading ||
                      translatePromptAgainLoading
                    }
                    onClick={() => generateAIContent()}
                    className={classes.postLoadingButton}
                  >
                    <RefreshIcon />
                  </LoadingButton>
                )}

                {generatedContent?.length === 0 && (
                  /*  <CommonButton
                type="submit"
                label={t("Write For Me")}
                disabled={loading}
                onSubmit={generateAIContent}
              /> */
                  <LoadingButton
                    loading={loading}
                    onClick={() => generateAIContent()}
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={
                      loading ||
                      tagsRequired ||
                      translatePrompLoading ||
                      translatePromptAgainLoading
                    }
                    className={classes.PostAiButton}
                  >
                    {t("Write_For_Me")}
                  </LoadingButton>
                )}
                {generatedContent?.length > 0 && (
                  /*  <CommonButton
                type="submit"
                label={t("Submit")}
                disabled={loading}
                onSubmit={handleSubmit}
              /> */
                  <LoadingButton
                    loading={loading}
                    disabled={
                      loading ||
                      tagsRequired ||
                      translatePrompLoading ||
                      translatePromptAgainLoading
                    }
                    onClick={() => handleSubmit()}
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.postSubmitButton}
                  >
                    {t("Submit")}
                  </LoadingButton>
                )}
              </Grid>
            </Grid>
          </Box>
        </Dialog>
      )}
      <Grid container spacing={4} md={12} className="postcard_grid">
        <Grid item xs={11} sm={11} md={4} spacing={2}>
          {showEditAll ? (
            <>
              <Typography className={classes.bulkEdit}>Bulk Edit</Typography>
              <Typography className={classes.bulkEditSubHead}>
                {t(
                  "All changes done in the form will be updated across all posts except images."
                )}
              </Typography>
              <Box sx={{ marginTop: "16px", display: "flex", gap: "8px" }}>
                <CommonButton
                  onSubmit={handleUpdateDateAndTime}
                  label={t("Bulk_Date_Time")}
                />
                {bulkImages && (
                  <CommonButton
                    onSubmit={() => updateBulkImages(true)}
                    label={t("bulk_images")}
                  />
                )}
              </Box>
              <BulkUpdateModel
                handleUpdateDateAndTime={handleUpdateDateAndTime}
                showBulkUpdateTime={showBulkUpdateTime}
                handleCloseBlukUpdateModel={handleCloseBlukUpdateModel}
                changeHandlerBulkDateUpdate={changeHandlerBulkDateUpdate}
                setEndValidDate={setEndValidDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDateGreater={setEndDateGreater}
                repeat={repeatPost}
              />
            </>
          ) : (
            <Box
              // className={classes.lazyLoadBox}
              sx={{
                position: "relative",
                marginTop: "8px",
                marginLeft: "8px",
              }}
            >
              <LazyLoadImage
                loading="lazy"
                //  key={item.id}
                src={
                  loadImg
                    ? noImageGray
                    : data?.postPictures[0]?.url
                    ? data?.postPictures[0]?.url
                    : ""
                }
                beforeLoad={handleBeforeLOad}
                afterLoad={handleAfterLOad}
                style={{
                  width: "100%",
                  height: "196px",
                  cursor: "pointer",
                  borderRadius: "8px",
                }}
                PlaceholderSrc={noImageGray}
                alt="Image Alt"
                effect="blur"
              />
              <Box>
                <input
                  id="file-id1"
                  type="file"
                  accept=".jpg, .png, .jpeg"
                  multiple={true}
                  ref={inputFileRef}
                  onChange={onFileChangeCapture}
                  style={{ display: "none" }}
                />

                <Box mt={3}>
                  <img
                    alt=""
                    src={CamBtnIcon}
                    onClick={handleButtonClick}
                    style={{
                      position: "absolute",
                      width: "80px",
                      bottom: "3%",
                      right: "3%",
                      cursor: "pointer",
                    }}
                  />
                </Box>
              </Box>
            </Box>
          )}
        </Grid>

        <Grid item xs={11} md={4}>
          <Grid item md={12}>
            <Typography className={classes.locationTypo}>
              {t("Select_Location")}
            </Typography>
            {/* <Autocomplete
              multiple
              disablePortal
              id="combo-box-demo"
              options={
                allLocation?.results?.length > 0 ? allLocation?.results : []
              }
              getOptionLabel={(option) =>
                option?.internalName
                  ? option?.internalName +
                    " " +
                    "(" +
                    (option?.addressLine1 !== "" &&
                    option?.addressLine1 !== null &&
                    option?.addressLine1 !== undefined
                      ? option?.addressLine1 + "," + " "
                      : "") +
                    (option?.city ? option?.city : "") +
                    ")"
                  : ""
              }
              open={true}
              className="Autocomplete-field"
              value={locationId ? locationId : []}
              onChange={(e, value) => {
                handleChangeLocation(
                  value.map((item) => item.id),
                  index,
                  value
                );
                handleChangeLocationData &&
                  handleChangeLocationData(value.map((item) => item));

                if (value?.length > 0) {
                  setLocationRequired(false);
                } else {
                  setLocationRequired(true);
                }
                handleLocationChange(value);
                 

                if (value?.length > 0) {
                  const defaultLocationObj = value?.find(
                    (item) => item?.defaultLocation !== null
                  );
                  if (
                    defaultLocationObj !== undefined &&
                    defaultLocationObj !== null
                  ) {
                    if (data===undefined) {
                      setDefaultLocation(defaultLocationObj);
                    } else {
                      handleMakeDefaultLocation(defaultLocationObj, index);
                    }
                  } else {
                    if (data===undefined) {
                      setDefaultLocation(value[0]);
                    } else {
                      handleMakeDefaultLocation(value[0], index);
                    }
                  }
                } else {
                  if (data===undefined) {
                    setDefaultLocation(defaultLocationByDefault);
                    handleMakeDefaultLocation(defaultLocationByDefault, index);
                  } else {
                    handleMakeDefaultLocation(defaultLocationByDefault, index);
                  }
                }

                if (value.find((option) => option.id === 0)) {
                  // Check if all options are selected
                  if (allLocation?.results.length === locationId?.length) {
                    setLocationId([]);
                    setSelectAll(false);
                  } else {
                    setSelectAll(true);
                    setLocationId(allLocation?.results);
                    setLocationRequired(false);
                  }
                } else {
                  setLocationId(value);
                  setSelectAll(false);
                }
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                return [{ id: 0, internalName: "Select All" }, ...filtered];
              }}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selectAll || selected}
                  />
                  {`${option?.internalName}  ${
                    option.city===undefined ||
                    option?.addressLine1===undefined
                      ? ""
                      : "("
                  } ${
                    option?.addressLine1 !== "" &&
                    option?.addressLine1 !== null &&
                    (option?.addressLine1===undefined
                      ? ""
                      : option?.addressLine1)
                      ? option?.addressLine1 + "," + " "
                      : ""
                  }${option.city===undefined ? "" : option.city} ${
                    option.city===undefined ||
                    option?.addressLine1===undefined
                      ? ""
                      : ")"
                  }`}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  name="language"
                  {...params}
                  error={data !== undefined && locationRequired===true}
                  label={t("Select Location")}
                />
              )}
            ></Autocomplete> */}
            <Autocomplete
              multiple
              error={locationId.length === 0 ? true : false}
              disablePortal
              id="combo-box-demo"
              disableCloseOnSelect
              options={
                allLocation?.results?.length > 0 ? allLocation?.results : []
              }
              value={locationId ? locationId : []}
              onChange={(e, value) => {
                const selectAllObj = value?.find(
                  (item) => item?.internalName === "Select All"
                );
                if (selectAllObj !== null && selectAllObj !== undefined) {
                  handleChangeLocation(
                    allLocation?.results.map((item) => item.id),
                    index,
                    allLocation?.results
                  );
                  handleChangeLocationData &&
                    handleChangeLocationData(
                      allLocation?.results.map((item) => item)
                    );
                } else {
                  handleChangeLocation(
                    value.map((item) => item.id),
                    index,
                    value
                  );
                  handleChangeLocationData &&
                    handleChangeLocationData(value.map((item) => item));
                }

                if (value?.length > 0) {
                  setLocationRequired(false);
                } else {
                  setLocationRequired(true);
                }
                // handleLocationChange(value);

                if (value?.length > 0) {
                  if (selectAllObj !== null && selectAllObj !== undefined) {
                    const defaultLocationObj = allLocation?.results?.find(
                      (item) => item?.defaultLocation !== null
                    );
                    if (
                      defaultLocationObj !== undefined &&
                      defaultLocationObj !== null
                    ) {
                      if (data === undefined) {
                        setDefaultLocation(defaultLocationObj);
                      } else {
                        handleMakeDefaultLocation(defaultLocationObj, index);
                      }
                    } else {
                      if (data === undefined) {
                        setDefaultLocation(allLocation?.results[0]);
                      } else {
                        handleMakeDefaultLocation(
                          allLocation?.results[0],
                          index
                        );
                      }
                    }
                  } else {
                    const defaultLocationObj = value?.find(
                      (item) => item?.defaultLocation !== null
                    );
                    if (
                      defaultLocationObj !== undefined &&
                      defaultLocationObj !== null
                    ) {
                      if (data === undefined) {
                        setDefaultLocation(defaultLocationObj);
                      } else {
                        handleMakeDefaultLocation(defaultLocationObj, index);
                      }
                    } else {
                      if (data === undefined) {
                        setDefaultLocation(value[0]);
                      } else {
                        handleMakeDefaultLocation(value[0], index);
                      }
                    }
                  }
                } else {
                  if (data === undefined) {
                    setDefaultLocation(defaultLocationByDefault);
                    handleMakeDefaultLocation(defaultLocationByDefault, index);
                  } else {
                    handleMakeDefaultLocation(defaultLocationByDefault, index);
                  }
                }

                if (value.find((option) => option.id === 0)) {
                  // Check if all options are selected
                  if (allLocation?.results.length === locationId?.length) {
                    setLocationId([]);
                    setSelectAll(false);
                  } else {
                    setSelectAll(true);
                    setLocationId(allLocation?.results);
                    //  setLocationRequired(false);
                  }
                } else {
                  setLocationId(value);
                  if (value?.length === allLocation?.results?.length) {
                    setSelectAll(true);
                  } else {
                    setSelectAll(false);
                  }
                }
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                if (allLocation?.results?.length > 0) {
                  return [{ id: 0, internalName: "Select All" }, ...filtered];
                } else {
                  return [];
                }
              }}
              getOptionLabel={(option) =>
                option?.internalName
                  ? option?.internalName +
                    " " +
                    "(" +
                    (option?.addressLine1 !== "" &&
                    option?.addressLine1 !== null &&
                    option?.addressLine1 !== undefined
                      ? option?.addressLine1 + "," + " "
                      : "") +
                    (option?.city ? option?.city : "") +
                    ")"
                  : ""
              }
              sx={{ minWidth: 300 }}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    id="select-all-checkbox"
                    checked={selectAll || selected}
                    // checked={selected}
                  />

                  {`${option?.internalName} ${
                    option?.addressLine1 !== "" &&
                    option?.addressLine1 !== null &&
                    option?.addressLine1 !== undefined
                      ? "(" + option?.addressLine1 + "," + " "
                      : ""
                  } ${option?.city ? option?.city + ")" : ""}`}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  name="language"
                  {...params}
                  error={
                    submitted && data !== undefined && locationId.length === 0
                      ? true
                      : false
                  }
                  label={t("Select_Locations")}
                />
              )}
            ></Autocomplete>
          </Grid>

          <Grid
            container
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "row",
              marginTop: "8px",
              justifyContent: "space-between",
            }}
          >
            <Grid item md={0.1}></Grid>
          </Grid>
          {data !== undefined && (
            <>
              <Grid
                item
                xs={5}
                sm={6}
                md={12}
                // md={2.75}
                sx={
                  {
                    /* , flexDirection: "column" */
                  }
                }
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  // md={2.75}
                  sx={
                    {
                      /* , flexDirection: "column" */
                    }
                  }
                >
                  <Typography className={classes.dateTimeTypo}>
                    {t("Select_Date_Time")}
                  </Typography>
                </Grid>
                <Grid container>
                  <Grid
                    item
                    xs={10.5}
                    sm={10.5}
                    md={10.5}
                    // md={2.75}
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["DateTimePicker"]}>
                        <DateTimePicker
                          //  minDateTime={dayjs(currentDate)}
                          classes={{
                            root: classes.root,
                          }}
                          disablePast
                          value={
                            data?.startDate
                              ? dayjs(data?.startDate)
                              : startDate !== null
                              ? dayjs(startDate)
                              : null
                          }
                          format={`${dateFormatString} hh:mm A`}
                          onChange={(e) => {
                            const currentDate = dayjs(new Date());
                            const selectedDateTime = dayjs(new Date(e));
                            const format = "DD/MM/YYYY hh:mm A";
                            const isValid =
                              e !== "" &&
                              selectedDateTime >= currentDate &&
                              isValidDateFormat(e, format);

                            setValidDate(isValid);

                            setStartDateRequired(false);
                            handleChangeStartDate(e, index);
                            setStartDate(e);
                            if (
                              (data?.endDate !== null &&
                                data?.endDate !== "" &&
                                data?.repeat !== "no") ||
                              (data === undefined &&
                                endDate !== null &&
                                endDate !== "" &&
                                (repeatPost === null || repeatPost !== "no"))
                            ) {
                              if (data === undefined) {
                                if (new Date(endDate) > new Date(e)) {
                                  setEndDateGreater(false);
                                } else {
                                  setEndDateGreater(true);
                                }
                              } else {
                                if (
                                  new Date(data?.endDate) >
                                  new Date(data?.startDate)
                                ) {
                                  setEndDateGreater(false);
                                } else {
                                  setEndDateGreater(true);
                                }
                              }
                            }
                          }}
                          helperText={ValidDate === false && "Invalid date"}
                          renderInput={(params) => (
                            <TextField
                              autoComplete="off"
                              fullWidth
                              {...params}
                              sx={{ background: "#FFFF" }}
                              // error={startDateRequired || ValidDate===false}
                            />
                          )}
                        />
                        <style>
                          {`
       .MuiDialogActions-root {
        display: none;
      }
     
    `}
                        </style>
                      </DemoContainer>
                    </LocalizationProvider>
                    {ValidDate === false && (
                      <Typography className={classes.fieldError}>
                        {t("Invalid_date")}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={1.5} sm={1.5} md={1.5}>
                    {postData.length === 1 && (
                      <CustomWidthTooltip title={t("Current_date_time")}>
                        <IconButton
                          sx={{
                            marginLeft: "5px",
                            /*   width: "100%",
                        height: "100%", */
                          }}
                          aria-label="delete"
                          size="large"
                          // color="red"
                          onClick={() => handleCurrentDateAndTime()}
                        >
                          <EventAvailableIcon fontSize="large" />
                        </IconButton>
                      </CustomWidthTooltip>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
          <Grid
            container
            spacing={1}
            sx={{ marginTop: "8px", marginBottom: "14px" }}
          >
            <Grid
              item
              sm={6}
              xs={6}
              // md={2.75}
              md={6}
              lg={6}
              sx={{ display: "flex", flexDirection: "column" }}
            >
              <Typography className={classes.locationTypo}>
                {t("Repeat_This_Post")}
              </Typography>

              <FormControl>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  value={data === undefined ? repeatPost : data?.repeat}
                  //  defaultValue={data?.repeat}
                  onChange={(e) => {
                    setRepeatPost(e.target.value);
                    handleChangeRepeatPost(e.target.value, index);
                    if (e.target.value === "no") {
                      setEndDateGreater(false);
                    } else if (
                      e.target.value === "custom"
                      // data?.repeat === "custom"
                    ) {
                      //  setSelectedPostData(data);
                      setOpenCustomModel(true);
                    } else {
                      if (data === undefined) {
                        setFrequencyValue("");
                        setSelectedMonthDays([]);
                        setSelectedWeekDays([]);
                        setOccuranceValue(1);
                        setOpenCustomModel(false);
                        handleChangeCustomDates([], index);
                        handleChangeCustomDays([], index);
                        handleChangeFrequency("", index);
                        handleChangeOccurance(1, index);
                      } else {
                        setOpenCustomModel(false);
                        handleChangeCustomDates([], index);
                        handleChangeCustomDays([], index);
                        handleChangeFrequency("", index);
                        handleChangeOccurance(1, index);
                      }
                    }
                  }}
                  error={
                    data === undefined
                      ? customError
                      : (data?.repeat === "custom" &&
                          data?.customSchedule?.occurance <= 0) ||
                        (data?.customSchedule?.frequency === "monthly" &&
                          data?.customSchedule?.dates?.length === 0) ||
                        (data?.customSchedule?.frequency === "weekly" &&
                          data?.customSchedule?.days?.length === 0)
                  }
                >
                  <MenuItem value="no">{t("No")}</MenuItem>
                  <MenuItem value="daily">{t("Daily")}</MenuItem>
                  <MenuItem value="weekly">{t("Weekly")}</MenuItem>
                  <MenuItem value="monthly">{t("Monthly")}</MenuItem>
                  <MenuItem value="yearly">{t("Yearly")}</MenuItem>
                  <MenuItem value="custom">{t("custom_text")}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {data?.repeat === "no" ||
            (data === undefined &&
              (repeatPost === null || repeatPost === "no")) ? (
              <> </>
            ) : (
              <>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={6}
                  // md={2.75}
                  sx={{ display: "flex", flexDirection: "column" }}
                >
                  <Typography className={classes.locationTypo}>
                    {t("End_Date")}
                  </Typography>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    {/* <DemoContainer components={["DateTimePicker"]}> */}
                    <DateTimePicker
                      minDateTime={dayjs(currentDate)}
                      value={data?.endDate ? dayjs(data?.endDate) : endDate}
                      format={`${dateFormatString} hh:mm A`}
                      classes={{
                        root: classes.root,
                      }}
                      onChange={(e) => {
                        const currentDate = new Date();
                        const selectedDateTime = new Date(e);
                        const format = "DD/MM/YYYY hh:mm A";
                        const isValid =
                          e !== "" &&
                          selectedDateTime >= currentDate &&
                          isValidDateFormat(e, format);

                        if (data === undefined) {
                          if (new Date(e) > new Date(startDate)) {
                            setEndDateGreater(false);
                          } else {
                            setEndDateGreater(true);
                          }
                        } else {
                          if (new Date(e) > new Date(data?.startDate)) {
                            setEndDateGreater(false);
                          } else {
                            setEndDateGreater(true);
                          }
                        }
                        setEndDate(e);
                        handleChangeEndDate(e, index);
                        setEndDateRequired(false);
                        setEndValidDate(isValid);
                      }}
                      renderInput={(params) => (
                        <TextField
                          sx={{ background: "#FFFF" }}
                          {...params}
                          error={
                            endDateRequired || !EndValidDate || endDateGreater
                          }
                          helperText={
                            (!EndValidDate || endDateGreater) && "Invalid date"
                          }
                        />
                      )}
                    />
                    <style>
                      {`
           .MuiDialogActions-root {
            display: none;
          }
         
        `}
                    </style>
                    {/* </DemoContainer> */}
                  </LocalizationProvider>
                  {(!EndValidDate || endDateGreater) && (
                    <Typography className={classes.fieldError}>
                      {t("Invalid_date")}
                    </Typography>
                  )}
                </Grid>
              </>
            )}

            {((data !== undefined &&
              data?.repeat === "custom" &&
              data?.customSchedule?.occurance > 0 &&
              ((data?.customSchedule?.frequency === "monthly" &&
                data?.customSchedule?.dates?.length > 0) ||
                (data?.customSchedule?.frequency === "weekly" &&
                  data?.customSchedule?.days?.length > 0) ||
                data?.customSchedule?.frequency === "daily")) ||
              (data === undefined &&
                customError === false &&
                repeatPost === "custom" &&
                (frequencyValue === "monthly" ||
                  frequencyValue === "weekly" ||
                  frequencyValue === "monthly" ||
                  frequencyValue === "daily"))) && (
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Grid
                  container
                  // spacing={2}
                  sx={{
                    backgroundColor: "#E0F7FF",

                    borderRadius: "8px",
                    marginTop: "12px",
                    padding: "12px 8px 5px 8px",
                  }}
                >
                  <Grid item xs={1} sm={1} md={1} lg={1}>
                    <img
                      alt=""
                      src={CalendarMonthIcon}
                      // style={{ marginLeft: "15px", marginTop: "5px" }}
                    />
                  </Grid>
                  {(data?.customSchedule?.frequency === "monthly" ||
                    (data === undefined && frequencyValue === "monthly")) && (
                    <Grid
                      item
                      xs={10}
                      sm={10}
                      md={10}
                      lg={10}
                      // sx={{ margin: "15px" }}
                    >
                      {data === undefined ? (
                        <Typography key={index}>
                          {`${t("every")} ${
                            occuranceValue !== 0 ? occuranceValue : ""
                          } ${
                            occuranceValue === 1
                              ? t("month_text")
                              : t("months_text")
                          }   ${selectedMonthDays
                            ?.map((value) => {
                              const lastDigit = value % 10;
                              const suffix =
                                value === 11 || value === 12 || value === 13
                                  ? "th"
                                  : lastDigit === 1
                                  ? "st"
                                  : lastDigit === 2
                                  ? "nd"
                                  : lastDigit === 3
                                  ? "rd"
                                  : "th";

                              return `${value}${suffix}`;
                            })
                            .join(", ")}`}
                        </Typography>
                      ) : (
                        <Typography key={index}>
                          {`${t("every")} ${
                            data?.customSchedule?.occurance !== 0
                              ? data?.customSchedule?.occurance
                              : ""
                          } ${
                            data?.customSchedule?.occurance === 1
                              ? t("month_text")
                              : t("months_text")
                          } ${data?.customSchedule?.dates
                            ?.map((value) => {
                              const lastDigit = value % 10;
                              const suffix =
                                value === 11 || value === 12 || value === 13
                                  ? "th"
                                  : lastDigit === 1
                                  ? "st"
                                  : lastDigit === 2
                                  ? "nd"
                                  : lastDigit === 3
                                  ? "rd"
                                  : "th";

                              return `${value}${suffix}`;
                            })
                            .join(", ")}`}
                        </Typography>
                      )}
                    </Grid>
                  )}
                  {(data?.customSchedule?.frequency === "weekly" ||
                    (data === undefined && frequencyValue === "weekly")) && (
                    <Grid
                      item
                      xs={10}
                      sm={10}
                      md={10}
                      lg={10}
                      // sx={{ margin: "15px" }}
                    >
                      {data === undefined ? (
                        <Typography>
                          {`${t("every")} ${
                            occuranceValue !== 0 && occuranceValue > 1
                              ? occuranceValue
                              : ""
                          } ${
                            occuranceValue === 1
                              ? t("week_text")
                              : t("weeks_text")
                          } ${selectedWeekDays
                            ?.map((value) => {
                              let str = `${
                                value === 0
                                  ? t("sunday")
                                  : value === 1
                                  ? t("monday")
                                  : value === 2
                                  ? t("tuesday")
                                  : value === 3
                                  ? t("wednesday")
                                  : value === 4
                                  ? t("thursday")
                                  : value === 5
                                  ? t("friday")
                                  : t("saturday")
                              }`;

                              return str;
                            })
                            .join(", ")}`}
                        </Typography>
                      ) : (
                        <Typography key={index}>
                          {`${t("every")} ${
                            data?.customSchedule?.occurance !== 0 &&
                            data?.customSchedule?.occurance > 1
                              ? data?.customSchedule?.occurance
                              : ""
                          } ${
                            data?.customSchedule?.occurance === 1
                              ? t("week_text")
                              : t("weeks_text")
                          } ${data?.customSchedule?.days
                            ?.map((value) => {
                              let str = `${
                                value === 0
                                  ? t("sunday")
                                  : value === 1
                                  ? t("monday")
                                  : value === 2
                                  ? t("tuesday")
                                  : value === 3
                                  ? t("wednesday")
                                  : value === 4
                                  ? t("thursday")
                                  : value === 5
                                  ? t("friday")
                                  : t("saturday")
                              }`;

                              return str;
                            })
                            .join(", ")}`}
                        </Typography>
                      )}
                    </Grid>
                  )}
                  {(data?.customSchedule?.frequency === "daily" ||
                    (data === undefined && frequencyValue === "daily")) && (
                    <Grid
                      item
                      xs={10}
                      sm={10}
                      md={10}
                      lg={10}
                      // sx={{ margin: "15px" }}
                    >
                      {data === undefined ? (
                        <Typography>
                          {occuranceValue !== 0 &&
                            (occuranceValue === 1
                              ? t("Daily")
                              : occuranceValue > 1
                              ? `${t("every")} ${
                                  occuranceValue ? occuranceValue : ""
                                } ${
                                  occuranceValue > 1
                                    ? t("days_text")
                                    : t("days")
                                } `
                              : "")}
                        </Typography>
                      ) : (
                        <Typography>
                          {data?.customSchedule?.occurance !== 0 &&
                            (data?.customSchedule?.occurance === 1
                              ? t("Daily")
                              : data?.customSchedule?.occurance > 1
                              ? `${t("every")} ${
                                  data?.customSchedule?.occurance
                                    ? data?.customSchedule?.occurance
                                    : ""
                                } ${
                                  data?.customSchedule?.occurance > 1
                                    ? t("days_text")
                                    : t("days")
                                } `
                              : "")}
                        </Typography>
                      )}
                    </Grid>
                  )}
                  <Grid item xs={1} sm={1} md={1} lg={1}>
                    <EditIcon
                      sx={{
                        color: "#06BDFF",
                        // marginRight: "15px",
                        cursor: "pointer",
                      }}
                      onClick={() => setOpenCustomModel(true)}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}

            <Grid item md={0.5}></Grid>
            {/*             {((data===undefined && customError && repeatPost === "custom") ||
              (data !== undefined &&
                data?.repeat === "custom" &&
                ((data?.customSchedule?.frequency==="monthly" &&
                  data?.customSchedule?.dates?.length===0) ||
                  (data?.customSchedule?.frequency==="weekly" &&
                    data?.customSchedule?.days?.length===0) ||
                  data?.customSchedule?.occurance === 0))) && (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{ padding: "0px" }}
              >
                <Grid container className={classes.mainErrorCustom}>
                  <Grid item xs={1} sm={1} md={1} lg={1}>
                    <ErrorOutlineIcon className={classes.errorIcon} />
                  </Grid>
                  <Grid item xs={10} sm={10} md={10} lg={10}>
                    <Typography className={classes.fieldErrorCustom}>
                      {t("Invalid_values")}
                    </Typography>
                  </Grid>
                  <Grid item xs={1} sm={1} md={1} lg={1}>
                    <EditIcon
                      sx={{
                        color: "#FF1616",
                        // marginRight: "15px",
                        cursor: "pointer",
                      }}
                      onClick={() => setOpenCustomModel(true)}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )} */}
          </Grid>
          <Box
            sx={{
              bgcolor: "#F6F6F7",
              width: "100%",
              borderRadius: "8px",
              marginY: "8px",
            }}
          >
            <Grid container>
              <Grid
                item
                xs={6}
                md={12}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  mb: 2,
                  marginX: "8px",
                }}
              >
                <Typography className={classes.googleCTATypo}>
                  {t("Google_My_CTA")}
                </Typography>
                <Grid container spacing={1}>
                  <Grid
                    md={6}
                    sm={6}
                    lg={6}
                    xs={12}
                    sx={{ marginTop: ".2rem" }}
                  >
                    <FormControl sx={{ width: "100%" }}>
                      <Select
                        onChange={(e) => {
                          handleChangeBusinessCta(e, index);
                          setDisplayUrlField(e.target.value);
                        }}
                        value={data?.postType}
                      >
                        <MenuItem value="ORDER">{t("OrderOnline")}</MenuItem>
                        <MenuItem value="BOOK">{t("Book")}</MenuItem>
                        <MenuItem value="SHOP">{t("Shop")}</MenuItem>
                        <MenuItem value="LEARN_MORE">
                          {t("Learn_More")}
                        </MenuItem>
                        <MenuItem value="SIGN_UP">{t("Sign_Up")}</MenuItem>
                        <MenuItem value="CALL">{t("CallNow")}</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  {data?.postType === "CALL" ||
                  (data === undefined &&
                    (displayUrlField === "" || displayUrlField === "CALL")) ? (
                    <> </>
                  ) : (
                    <Grid item xs={12} md={6} sm={6} lg={6}>
                      <TextField
                        fullWidth
                        // label="URL"
                        error={urlRequired}
                        placeholder={t("Please_enter_Url")}
                        value={data?.url}
                        onChange={(e) => handleChange(e, index)}
                        helperText={urlRequired ? t("Invalid_Url") : ""}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
            {showEditAll ? (
              <FormGroup sx={{ ml: 2 }}>
                {/* <FormControlLabel control={<Checkbox defaultChecked />}label="Also add image to the Photos section of Google My Business?" /> */}
                <FormControlLabel
                  control={
                    <Checkbox
                      onClick={(e) => {
                        handleChangeCheckbox(e, index);
                        setChecked(e.target.checked);
                      }}
                      checked={checked}
                    />
                  }
                  label={t("img_desc")}
                />
              </FormGroup>
            ) : (
              <FormGroup sx={{ ml: 2 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onClick={(e) => {
                        setChecked(e?.target?.checked);
                        handleChangeCheckbox(e, index);
                      }}
                      checked={data?.isUploadPicture === true ? true : false}
                      //defaultChecked
                    />
                  }
                  label={t("img_desc")}
                />
              </FormGroup>
            )}

            {showEditAll ? (
              <FormGroup sx={{ ml: 2 }}>
                {/* <FormControlLabel control={<Checkbox defaultChecked />}label="Also add image to the Photos section of Google My Business?" /> */}
                <FormControlLabel
                  control={
                    <Checkbox
                      onClick={(e) => {
                        handleChangeCheckboxUberAll(e, index);
                        setCheckedUberAll(e.target.checked);
                      }}
                      checked={checkedUberAll}
                    />
                  }
                  label={t("img_desc_uberAll")}
                />
              </FormGroup>
            ) : (
              <FormGroup sx={{ ml: 2 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onClick={(e) => {
                        // setCheckedUberAll(e?.target?.checked);
                        handleChangeCheckboxUberAll(e, index);
                      }}
                      checked={data?.uploadUberAll === true ? true : false}
                      //defaultChecked
                    />
                  }
                  label={t("img_desc_uberAll")}
                />
              </FormGroup>
            )}
          </Box>
          {data !== undefined &&
            data?.errorUberAll === true &&
            data?.uploadUberAll === true && (
              <Grid container sx={{ marginBottom: "5px" }}>
                <Grid container className={classes.mainContainerUberAllForCard}>
                  <Grid
                    item
                    xs={0.7}
                    sm={0.7}
                    md={0.7}
                    lg={0.7}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    {" "}
                    <BootstrapTooltip
                      title={
                        <h1 style={{ fontSize: "12px" }}>
                          {t("Additional_photos_tooltipText")}
                        </h1>
                      }
                    >
                      <ErrorOutlineIcon className={classes.errorIconUberAll} />
                    </BootstrapTooltip>
                  </Grid>
                  <Grid item xs={11} sm={11} md={11} lg={11}>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      sx={{ display: "flex" }}
                    >
                      <Typography className={classes.errorTextUberAll}>
                        {t("uberAll_error")}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={0.3}
                    sm={0.3}
                    md={0.3}
                    lg={0.3}
                    sx={{
                      textAlign: "end",
                    }}
                  >
                    <CloseIcon
                      fontSize="small"
                      onClick={() => handleClickCloseWarning(index)}
                      className={classes.closeIconUberAll}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
        </Grid>

        <Grid item xs={11} md={3}>
          <Box sx={{ display: "flex" }}>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "700",
                color: "#1B2430",
                lineHeight: "20px",
                marginBottom: "8px",
              }}
            >
              {t("Post_Summary")} {""}
            </Typography>
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: "400",
                color: "#1B2430",
                lineHeight: "20px",
                marginBottom: "8px",
                ml: 2,
              }}
            >
              {" "}
              {data !== undefined ? data?.content?.length : descriptionText}
              /1500{" "}
            </Typography>
          </Box>

          {data?.content?.length >= 1499 && (
            <Typography className={classes.textLimitTypo}>
              {t("text_limit")}
            </Typography>
          )}
          <TextField
            id="standard-basic"
            // label="Standard"
            rows={18}
            maxRows={50}
            value={
              data !== undefined
                ? data?.content
                : generatedContent?.length > 0
                ? generatedContent
                : ""
            }
            onChange={(e) => {
              handleChangeDescription(e, index);
              setDescription(e.target?.value.length);
              setGeneratedContent(e.target?.value);

              // setContentRequired(false);
            }}
            style={{
              fontFamily: "Segoe UI",
              fontStyle: "Normal",
              width: "100%",
              borderRadius: "8px",
              color: "#1B2430",
              fontWeight: "400",
              fontSize: "16px",
            }}
            label={null}
            multiline
            fullWidth
            ref={textArea}
            placeholder={t("Enter_Description")}
            inputProps={{ minLength: 0, maxLength: 1500 }}
            InputProps={{
              endAdornment: (
                <Box sx={{ marginRight: "22px" }}>
                  <InputAdornment position="bottom">
                    <Box className={classes.postAIBox}>
                      <CustomWidthTooltip
                        // color="red"
                        title={t("Post_Ai_Tooltip")}
                      >
                        <IconButton
                          onClick={() => handleOpenTemplateMenu(data)}
                          disabled={
                            loctionLoadings === true
                              ? true
                              : allLocation?.results?.length > 0
                              ? false
                              : true
                          }
                        >
                          <AiLogoGrey />
                        </IconButton>
                      </CustomWidthTooltip>
                    </Box>
                  </InputAdornment>
                </Box>
              ),
            }}
          />
        </Grid>
        <Grid
          item
          md={0.5}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          {!showEditAll && (
            <Item>
              <CustomWidthTooltipOfDelete
                // color="red"
                title={t("Delete")}
              >
                <IconButton
                  className={classes.IconButtonDeletePost}
                  aria-label="delete"
                  size="large"
                  // color="red"
                  onClick={(e) => {
                    handleDeletePost(e, index);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </CustomWidthTooltipOfDelete>
            </Item>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default PostCard;
